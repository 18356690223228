import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LeaderBoardAd from "../../Components/LeaderBoardAd";
import Ads from "../ads";
import "../../App";

const LiveScorePageV9 = () => {
  const history = useHistory();
  const location = useLocation();
  const [countdown, setCountdown] = useState(5);
  const [showCountdown, setShowCountdown] = useState(true);

  useEffect(() => {
    // Set a timer to hide the countdown after 5 seconds
    const timer = setTimeout(() => {
      setShowCountdown(false);
    }, countdown * 1000);

    // Clear the timer if the component unmounts before 5 seconds
    return () => clearTimeout(timer);
  }, [history, location.search]);

  const getTableUrl = () => {
    const params = new URLSearchParams(location.search);
    const gameKey = params.get("game");
    return `https://marriagepointcalculator.netlify.app/#/LiveScorePageV9?gamekey=${gameKey}`;
  };

  useEffect(() => {
    // Google add scense script added
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6780615883898279";
    script.async = true;
    document.body.appendChild(script);
    return () => {};
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clean up the timer when the component unmounts or when the countdown reaches 0
    return () => clearInterval(timer);
  }, []);

  <style>
    {`
    @keyframes pulse {
      0% {
        transform: translate(-50%, -50%) scale(1);
        background-color: white;
      }
      50% {
        transform: translate(-50%, -50%) scale(1.1);
        background-color: lightgray;
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
        background-color: white;
      }
    }
  `}
  </style>;

  return (
    <>
      <div>
        {showCountdown ? (
          <div class="body-wrapper container">
            <div class="centered-text" id="countdown">
              <h3>Establishing Connection...</h3>
              <h5>Hold on, we're connecting you to the server.</h5>
              <h1
                style={{
                  fontSize: "100px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  width: "200px",
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "5px solid white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  animation: "pulse 2s infinite ease-in-out",
                }}
              >
                {countdown}
              </h1>
            </div>
            {/* <LeaderBoardAd
              adClient="ca-pub-6780615883898279"
              adSlot="7757082732"
            /> */}
          </div>
        ) : (
          <div className="scoreboard">
            {" "}
            <iframe
              src={getTableUrl()} // Replace with the URL of the webpage you want to load
              title="External Web Page"
              style={{ width: "100%", height: "100vh", border: "none" }}
            />
          </div>
        )}
      </div>
      <Ads />
    </>
  );
};

export default LiveScorePageV9;
